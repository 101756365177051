body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
	text-align: center;
}

@font-face {
	font-family: "avenir-heavy";
	src: url(/static/media/AvenirNextLTPro-Heavy.d9ad575e.woff)
		format("woff");
}

@font-face {
	font-family: "avenir-light";
	src: url(/static/media/AvenirNextLTPro-UltLt.f9d611fb.woff)
		format("woff");
}

.mmia-header-heavy {
	font-family: "avenir-heavy";
	font-size: 60px;
	color: #1a1a1a;
	margin: 0;
}

.App-logo {
	-webkit-animation: App-logo-spin infinite 20s linear;
	        animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
}

.App-header {
	height: 90vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2px;
}

.App-body {
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2px;
}

.App-link {
	color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

