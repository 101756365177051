.App {
	text-align: center;
}

@font-face {
	font-family: "avenir-heavy";
	src: url("./assets/fonts/AvenirNextHeavy/AvenirNextLTPro-Heavy.woff")
		format("woff");
}

@font-face {
	font-family: "avenir-light";
	src: url("./assets/fonts/AvenirNextHeavy/AvenirNextLTPro-UltLt.woff")
		format("woff");
}

.mmia-header-heavy {
	font-family: "avenir-heavy";
	font-size: 60px;
	color: #1a1a1a;
	margin: 0;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
}

.App-header {
	height: 90vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2px;
}

.App-body {
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2px;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
